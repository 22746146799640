import React from 'react'

import Helmet from '../components/Helmet/Helmet';

import { Container, Row, Col } from 'reactstrap';

import ContactForm from '../components/Forms/ContactForm';

import '../styles/contact.css'

const Contact = () => {
    return <Helmet title={'Contact us'}>
        <section>
            <Container>
                <Row>
                    <div className='padding-global'>
                        <h1 className='page-heading'>Contact us</h1>
                        <p className='page-sub-heading'>Connect with us by sharing your details below, and let us curate the perfect response just for you.</p>

                        <div className='contact-box'>
                            <Col lg='6' md='12' sm='12'>
                                <div className='contact-form'>
                                    <ContactForm />
                                </div>
                            </Col>

                            <Col lg='6' md='12' sm='12'>
                                <div className='contact-content'>
                                    <div className='contact-col'>
                                        <div className='contact-item'>
                                            <i class="ri-mail-line contact-icon"></i>
                                            <h3 className='contact'>Email</h3>
                                            <p>thevisiongarments00@gmail.com</p>
                                        </div>
                                        <div className='contact-item'>
                                            <i class="ri-phone-line contact-icon"></i>
                                            <h3 className='contact'>Phone</h3>
                                            <p>+1 (406) 590-0534</p>
                                        </div>
                                    </div>
                                    <div className='contact-col'>
                                        <div className='contact-item'>
                                            <i class="ri-map-pin-line contact-icon"></i>
                                            <h3 className='contact'>Arc Studios Gallery</h3>
                                            <p>10030 Pelletier Loop B </p>
                                            <p>Missoula, MT 59808</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </div>
                    </div>
                </Row>
            </Container>
        </section>
    </Helmet>
}

export default Contact;
