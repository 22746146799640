import React, {useState, useEffect} from 'react';
import { toast } from 'react-toastify';

import Helmet from '../components/Helmet/Helmet';

import { Container, Row } from 'reactstrap';

import heroGif from '../assets/videos/home-anime.mp4';

import ProductsList from '../components/UI/ProductsList';

import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase.config';

import '../styles/home.css';

const Home = () => {

    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
       const fetchProducts = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "products"));
                const productsList = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                const filteredProducts = productsList.filter(item => item.category === 'Physical');

                // Sort products by type: T-Shirts first, followed by Posters, then Carpets
                const sortedProducts = filteredProducts.sort((a, b) => {
                    const order = { tshirt: 1, poster: 2, carpet: 3 };
                    return order[a.productType] - order[b.productType];
                });
                setData(sortedProducts);
            } catch (error) {
                toast.error("Error fetching products: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchProducts();
    }, []);

    const textContent = 'GALLERY OF THE FUTURE.';
    const textContent2 = 'مستقبل الموضة';
    
    const textElements = [];
    for (let i = 0; i < 30; i++) {
        if (i % 3 === 0 && i !== 0){
            textElements.push(
                <span key={i} className="text-content">
                    {textContent2}
                </span>
        );}
        else {
            textElements.push(
                <span key={i} className="text-content">
                    {textContent}
                </span>
        );}
    }

    const mirroredTextElements = [];
    for (let i = 0; i < 20; i++) {
        if (i % 3 === 0 && i !== 0){
            mirroredTextElements.push(
                <span key={i} className="text-content mirror">
                    {textContent2}
                </span>
        );}
        else {
            mirroredTextElements.push(
                <span key={i} className="text-content mirror">
                    {textContent}
                </span>
        );}
    }

    return (
        <Helmet title="Home" description="The fashion gallery of the future">
            <section>
                <Container>
                    <div className="hero-content">
                        <video
                            src={heroGif}
                            playsInline
                            autoPlay
                            loop
                            muted
                            className="hero-gif"
                        />
                    </div>
                </Container>
                <div className='animation-wrapper'>
                  <div class="text-slider">{textElements}</div>
                  <div class="text-slider-mirror">{mirroredTextElements}</div>
                  <div class="text-slider">{textElements}</div>
                </div>
            </section>

            <section id='#products' className='section'>
                <Container>
                    <Row className='gallery'>
                        {loading && <div> Loading Products... </div>}
                        <ProductsList data={data} />
                    </Row>
                </Container>
            </section>
        </Helmet>
    );
};

export default Home;
