import React, { useEffect, useState } from 'react';

import { Container, Row, Col, ListGroup, ListGroupItem } from 'reactstrap';

import logo from  '../../assets/images/thevisionlogowhite.png';
import './footer.css';
import { Link } from 'react-router-dom';

import { useAuth } from '../../custom-hooks/useAuth';

const Footer = () => {
  const year = new Date().getFullYear();
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
     const user = localStorage.getItem('user');
     setCurrentUser(user);
  })
 

  return <footer className='footer'>
      <Container>
          <Row>
              <Col lg='4' className='mb-4' md='6'>
                  <div className='footer-logo'>
                      <Link to='/home'><img src={logo} alt='logo' /></Link>
                  </div>
                  <p className='footer-text '>
                      Our style meets bold abstraction. Futuristic designs, sleek and innovative. 
                      Each piece, a wearable feeling. Showcase your vision effortlessly. 
                      Experience the future of fashion.
                  </p>
              </Col>

              <Col lg='3' md='3' className='mb-4'>
                  <div className='footer_quick-links'>
                      <span className='quick_links-title'>Top Categories</span>
                      <ListGroup className='mb-3'>
                          <ListGroupItem className='ps-0 border-0'>
                              <Link to='/home#products'>Garments</Link>
                          </ListGroupItem>

                          <ListGroupItem className='ps-0 border-0'>
                              <Link to='/retina'>Art Studios Gallery</Link>
                          </ListGroupItem>
                      </ListGroup>
                  </div>
              </Col>

              <Col lg='2' md='3' className='mb-4'>
                  <div className='footer_quick-links'>
                        <span className='quick_links-title'>Useful Links</span>
                        <ListGroup className='mb-3'>
                            {!currentUser && (
                                <>
                                    <ListGroupItem className='ps-0 border-0'>
                                        <Link to='/login'>Login</Link>
                                    </ListGroupItem>
                                    <ListGroupItem className='ps-0 border-0'>
                                        <Link to='/signup'>Signup</Link>
                                    </ListGroupItem>
                                </>
                            )}
                            <ListGroupItem className='ps-0 border-0'>
                                <Link to='/privacy-policy'>Privacy policy</Link>
                            </ListGroupItem>
                        </ListGroup>
                  </div>
              </Col>

              <Col lg='3' md='4'>
                  <div className='footer_quick-links'>
                      <span className='quick_links-title'>Contact</span>
                      <ListGroup className='footer-contact'>
                          <ListGroupItem className='ps-0 border-0 d-flex align-items-center gap-2'>
                              <span><i class="ri-map-pin-line"></i></span>
                              <p>10030 Pelletier Loop B Missoula, MT 59808</p>
                          </ListGroupItem>

                          <ListGroupItem className='ps-0 border-0 d-flex align-items-center gap-2'>
                              <span><i class="ri-phone-line"></i></span>
                              <p >+1 (406) 590-0534</p>
                          </ListGroupItem>
                          
                          <ListGroupItem className='ps-0 border-0 d-flex align-items-center gap-2'>
                              <span><i class="ri-mail-line"></i></span>
                              <p>thevisiongarments00@gmail.com</p>
                          </ListGroupItem>
                      </ListGroup>
                  </div>
              </Col>

              <Col lg='12'>
                  <p className='footer-copyright'>
                      Copyright {year} lordvisiongarments. All rights reserved. 
                  </p>
              </Col>
          </Row>
      </Container>
  </footer>
}

export default Footer